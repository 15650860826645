import * as React from 'react';
import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { Layout } from '../common/layout';
import { Seo } from '../common/seo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    top: {
      backgroundColor: '#E1E1E1',
      padding: '50px 30px'
    },
    bottom: {
      padding: '50px 30px',
    },
    container: {
      maxWidth: 1000,
      width: '100%'
    },
    title: {
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(50)
    },
    titleSubheader: {
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(30)
    },
    topText: {
      marginTop: 20,
      marginLeft: 25,
      fontSize: theme.typography.pxToRem(20)
    },
    bottomText: {
      fontSize: theme.typography.pxToRem(20),
      transform: 'translate(0px, -11px)'
    },
    line: {
      width: 15,
      minWidth: 15,
      height: 1.5,
      backgroundColor: theme.palette.primary.main,
      margin: '0 25px 0 0'
    },
    borderLine: {
      width: 1.5,
      height: 'calc(100% - 24px)',
      backgroundColor: theme.palette.primary.main,
      position: 'absolute',
      [theme.breakpoints.down(385)]: {
        height: 'calc(100% - 46px)',
       },
    },
    bottomSection: {
      position: 'relative',
      margin: '40px 0 0 30px'
    }
  }),
);

const BOTTOM_TEXT = [
  'develop technology that is trustworthy and built for the greater good',
  'use data responsibly and protect it',
  'be transparent and direct especially when we make a mistake',
  'treat ethics as an essential component of internal decision-making',
  'listen and continuously improve '
];

export const AboutPage = () => {
  const classes = useStyles();
  return (
    <Layout>
      <Seo title='About Us' />
      <Box alignItems='center' display="flex" flexDirection="column" className={classes.top}>
        <Box className={classes.container}>
          <Typography className={classes.title}>
            re•bolt
          </Typography>
          <Typography className={classes.titleSubheader}>
            [rɪˈbəʊlt] noun
          </Typography>
          <Typography className={classes.topText}>
            1. a social impact tech start-up founded by BIPOC with the mission of providing visibility into the values and ethics of companies
          </Typography>
          <Typography className={classes.topText}>
            2. a movement to create a more transparent and equitable world
          </Typography>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" alignItems='center' className={classes.bottom}>
        <Box className={classes.container}>
          <Typography className={classes.title}>
            code of ethics
          </Typography>
          <Box className={classes.bottomSection}>
            <Box className={classes.borderLine} />
            {BOTTOM_TEXT.map((value, idx) => (
              <Box key={idx} display='flex' style={{ margin: '25px 0' }}>
                <Box className={classes.line} />
                <Typography className={classes.bottomText}>
                  {value}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Layout>
  )
}
